import auth0 from 'auth0-js'
import nonce from 'nonce'

// modules
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_CALLBACK_URL,
  AUTH_COOKIE_NAME,
  AUTH0_AUDIENCE,
  IMPERSONATION_COOKIE_NAME,
  ROLES_KEY
} from '../constants'
import UserRoles from '../userRoles'
import Env from '../env'
import { secondsToDays, secondsToMilliseconds } from '../time'
import Cookie from '../cookie'
import AppError from '../app-error'

// TODO: This module requires a refactoring in order to minimize and discard unused code.
export default class Auth {
  static forwardUrl = '/'
  static nonce = String(nonce()())

  static updateAuthForwardUrl = (location, forwardUrl) => {
    let pathNameAndSearch = forwardUrl
      ? forwardUrl
      : location.pathname + location.search

    const isPathNameAndSearchValidAsForwardUrl = !/(session|login|logout|callback|impersonation)+/g.test(
      pathNameAndSearch
    )

    if (isPathNameAndSearchValidAsForwardUrl)
      pathNameAndSearch = Auth.forwardUrl = forwardUrl
        ? forwardUrl
        : location.pathname + location.search

    if (pathNameAndSearch === 'undefined')
      pathNameAndSearch = Auth.forwardUrl = '/'

    return pathNameAndSearch
  }

  static auth0 = Env.isClient()
    ? new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENT_ID,
        redirectUri: AUTH0_CALLBACK_URL,
        responseType: 'token id_token',
        scope: 'openid profile'
      })
    : null

  static isTokenStillAlive(token) {
    return Date.now() < token._expiresAtAsMilliseconds
  }

  static getCookie(cookieNamespace, cb = () => null) {
    if (!cookieNamespace) return cb(new Error('Namespaced not specified'))
    Cookie.getAsJson(cookieNamespace, (cookieError, token) => {
      if (cookieError) return cb(cookieError)
      const sessionErrorCopy = 'UNAUTHORIZED'
      if (!token || !token.accessToken)
        return AppError.throw(sessionErrorCopy, cb)

      cb(null, token)
    })
  }

  static getToken(cb = () => null) {
    Auth.getCookie(AUTH_COOKIE_NAME, (cookieError, token) => {
      if (cookieError) return cb(cookieError)
      if (Auth.isTokenStillAlive(token)) return cb(null, token.accessToken)
      Env.isClient(() => {
        Auth.auth0.checkSession({}, authError => {
          if (authError) return cb(authError)
          return cb(null, token.accessToken)
        })
      })
    })
  }

  static clientFetchProfile(cb = () => null) {
    Cookie.getAsJson(AUTH_COOKIE_NAME, (err, result) => {
      if (err) return cb(err)

      if (!result || !result.accessToken)
        return AppError.throw('User has not started session.', cb)

      const profile = result.idTokenPayload || {}
      cb(null, profile)
    })
  }

  static serverFetchProfile(cb = () => {}) {}
  static isoFetchProfile(cb = () => {}) {
    Env.isClient(() => Auth.clientFetchProfile(cb))
  }

  static clientIsAuth(cb = () => null) {
    return Cookie.getAsJson(AUTH_COOKIE_NAME, (err, token) => {
      if (err) return cb(err)
      cb(null, Auth.isTokenStillAlive(token))
    })
  }

  static serverIsAuth() {}
  static isoIsAuth(cb = () => null) {
    return Env.isClient(() => Auth.clientIsAuth(cb))
  }

  static login() {
    Env.isClient(() => {
      Auth.auth0.authorize({
        prompt: 'login',
        audience: AUTH0_AUDIENCE,
        state: Auth.nonce
      })
    })
  }

  static logout(originUrl) {
    Auth.forwardUrl = originUrl || Auth.forwardUrl
    Auth.isoClearSession(() => null)
  }

  static clientSetSession(cookieNamespace, result, cb = () => null) {
    const _expiresAtAsMilliseconds =
      Date.now() + secondsToMilliseconds(result.expiresIn)
    const tokenWithInfo = {
      _expiresAtAsMilliseconds,
      ...result
    }

    Cookie.setAsJson(
      cookieNamespace,
      tokenWithInfo,
      { expires: secondsToDays(result.expiresIn), path: '/' },
      err => {
        if (err) return cb(err)
        cb(null, result)
      }
    )
  }
  static serverSetSesion() {}

  static clientClearSession(cb = () => null) {
    Cookie.remove(AUTH_COOKIE_NAME, null, () => {
      Cookie.remove(IMPERSONATION_COOKIE_NAME, null, () => {
        // Client Side build HomeURL
        const { location } = Env.getWindow()

        Env.isClient(() => {
          Auth.auth0.logout({
            returnTo:
              location.protocol +
              '//' +
              location.host +
              '/?session=closed' +
              '&encodedForwardUrl=' +
              encodeURIComponent(Auth.forwardUrl)
          })
        })

        cb()
      })
    })
  }

  static serverClearSession() {}

  static isoClearSession(cb = () => {}) {
    Env.isClient(() => {
      Auth.clientClearSession(cb)
    })
  }

  static refreshRolesInCookie(cookieContent = null, cb = () => null) {
    UserRoles.fetchUserRoles((err, roles) => {
      if (err) return cb(err)
      const updateCookie = data => {
        data[ROLES_KEY] = roles
        Auth.storeTokenCookie(data, cb)
      }

      if (!cookieContent) {
        return Auth.getCookie(AUTH_COOKIE_NAME, (err, jwtData) => {
          if (err) return cb(err)
          updateCookie(jwtData)
        })
      }
      updateCookie(cookieContent)
    })
  }

  static storeTokenCookie(jwtData, cb) {
    Auth.clientSetSession(AUTH_COOKIE_NAME, jwtData, (err, token) => {
      if (err) return cb(err)
      if (!jwtData.hasOwnProperty(ROLES_KEY))
        return Auth.refreshRolesInCookie(token, cb)
      cb(null, token)
    })
  }

  static clientHandleAuthentication(cb = () => null) {
    Env.isClient(() => {
      Auth.auth0.parseHash((err, result) => {
        if (result && result.accessToken && result.idToken) {
          return Auth.storeTokenCookie(result, cb)
        } else if (err) {
          return Auth.clientClearSession(cb)
        }
      })
    })
  }
  static serverHandleAuthentication() {
    // Runs on server side only.
  }
  static isoHandleAuthentication(cb = () => null) {
    Env.isClient(() => {
      Auth.clientHandleAuthentication(cb)
    })
  }

  // Impersonation
  static serverIsImpersonating(cb) {
    // Runs on server side only
  }
  static clientIsImpersonating(cb) {
    Auth.getCookie(IMPERSONATION_COOKIE_NAME, (cookieError, token) => {
      if (cookieError) return cb(null)
      if (Auth.isTokenStillAlive(token)) return cb(null, token.accessToken)
      cb(null, (token && token.accessToken) || null)
    })
  }
  static isoIsImpersonating(cb = () => null) {
    Env.isClient(() => {
      Auth.clientIsImpersonating(cb)
    })
  }
}
