import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

function datejs (date) {
  return dayjs(date)
}

datejs.prototype.format = dayjs.prototype.format
datejs.prototype.subtract = dayjs.prototype.subtract
datejs.prototype.add = dayjs.prototype.add

export default datejs
