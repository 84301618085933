import datejs from '@modules/datejs'

export function millisecondsToDays(milliseconds) {
  return milliseconds / 1000 / 60 / 60 / 24
}

export function secondsToDays(seconds) {
  return seconds / 60 / 60 / 24
}

export function secondsToMilliseconds(seconds) {
  return seconds * 1000
}

export function dateToUTC(date = datejs()){
  return datejs(date).utc().format()
}