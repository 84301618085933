/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import axios from 'axios'
import { globalHistory } from '@reach/router'

// modules
import Router from './src/modules/router'
import wrapWithProvider from './wrap-with-provider'
import './src/styles/entry.scss'
import Auth from './src/modules/auth'
import Env from './src/modules/env'

export const wrapRootElement = wrapWithProvider

export const onClientEntry = (_, b) => {
  globalHistory.listen(({ location }) => {
    Auth.updateAuthForwardUrl(location)
  })

  axios.interceptors.request.use(
    config => config,
    err => Promise.reject(err)
  )

  axios.interceptors.response.use(
    res => {
      const { status, data } = res || {}
      switch (status) {
        case 200:
          if (
            typeof data === 'string' &&
            data.toString().indexOf('Page not found') !== -1
          ) {
            Router.go('/404')
            return Promise.reject(
              new Error('API Endpoint returned http status 404 Page Not Found')
            )
          }
          break
        case 204:
          Router.go('/404')
          return Promise.reject(
            new Error('API Endpoint returned http status 204 No Content')
          )
        default:
          break
      }
      return res
    },
    err => {
      const { status, config } = (err && err.response) || {
        status: null,
        config: null
      }
      const { method } = config || { method: '' }

      Auth.updateAuthForwardUrl(Env.getWindow().location)

      const methodName = method.toString().toLocaleLowerCase()
      switch (status) {
        case 401:
          Router.goToLoginWithSecuredForwardUrl()
          break
        case 403:
          Auth.refreshRolesInCookie(null, () => {
            Router.go('/403')
          })
          break
        case 404:
          if (methodName === 'get') {
            Router.go('/404')
          }
          break
        default:
          break
      }

      return Promise.reject(err)
    }
  )
}

export const onRouteUpdate = ({ location }) =>
  Auth.updateAuthForwardUrl(location)
