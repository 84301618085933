import { fromJS } from 'immutable'

import {
  SET_COUNTER,
  COUNTER,
  SET_AUTH_STATE,
  AUTH_STATE,
  SET_PROFILE,
  PROFILE,
  SET_IS_VIEW_AS_OPEN,
  IS_VIEW_AS_OPEN,
  IS_VIEW_AS_SELECTED,
  SET_IS_VIEW_AS_SELECTED,
  SET_COLLECTION_REQUEST_STATUS,
  COLLECTION_REQUEST_STATUS,
  SET_COLLECTION_REQUEST_ERROR,
  COLLECTION_REQUEST_ERROR,
  SET_COLLECTION,
  COLLECTION,
  SET_RESOURCE_REQUEST_STATUS,
  RESOURCE_REQUEST_STATUS,
  SET_RESOURCE_REQUEST_ERROR,
  RESOURCE_REQUEST_ERROR,
  SET_RESOURCE,
  RESOURCE,
  SET_MOBILE_MENU,
  MOBILE_MENU,
  SET_VIEWING_AS,
  VIEWING_AS,
  SET_LOCATION,
  LOCATION,
  PROFILE_REQUEST_STATUS,
  PROFILE_REQUEST_ERROR,
  SET_PROFILE_REQUEST_STATUS,
  SET_PROFILE_REQUEST_ERROR,
  SYNTHETIC_BREADCRUMB_ITEMS,
  SET_SYNTHETIC_BREADCRUMB_ITEMS,
  NOTIFICATION_POOL,
  ADD_NOTIFICATION_POOL,
  REMOVE_NOTIFICATION_POOL,
  UPDATE_NOTIFICATION_POOL,
  TAB_SUBMIT_BUTTON,
  SET_TAB_SUBMIT_BUTTON
} from './constants'

import { REST_API_ENDPOINTS } from '../constants'

const initialState = fromJS({
  [AUTH_STATE]: false,
  [COUNTER]: 0,

  [PROFILE_REQUEST_STATUS]: false,
  [PROFILE_REQUEST_ERROR]: null,
  [PROFILE]: {},

  [COLLECTION_REQUEST_STATUS]: {
    [REST_API_ENDPOINTS.breakdownStatusCounts]: true,
    [REST_API_ENDPOINTS.referrals]: true
  },
  [COLLECTION_REQUEST_ERROR]: {},
  [COLLECTION]: {
    [REST_API_ENDPOINTS.leads]: [],
    [REST_API_ENDPOINTS.referrals]: [],
    [REST_API_ENDPOINTS.advisors]: [],
    [REST_API_ENDPOINTS.contacts]: [],
    [REST_API_ENDPOINTS.businessUnits]: []
  },

  [RESOURCE_REQUEST_STATUS]: {},
  [RESOURCE_REQUEST_ERROR]: {},
  [RESOURCE]: {
    [REST_API_ENDPOINTS.leads]: {},
    [REST_API_ENDPOINTS.advisors]: {},
    [REST_API_ENDPOINTS.contacts]: {},
    [REST_API_ENDPOINTS.residents]: {},
    [REST_API_ENDPOINTS.referralStatuses]: {},
    [REST_API_ENDPOINTS.leadActivities]: {},
    [REST_API_ENDPOINTS.referrals]: {},
    [REST_API_ENDPOINTS.breakdownStatusCounts]: []
  },

  [MOBILE_MENU]: false,
  [IS_VIEW_AS_OPEN]: false,
  [IS_VIEW_AS_SELECTED]: false,
  [LOCATION]: {
    search: ''
  },

  [SYNTHETIC_BREADCRUMB_ITEMS]: [],

  [NOTIFICATION_POOL]: [],

  [TAB_SUBMIT_BUTTON]: null
})

export default function(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_COUNTER:
      return state.set(COUNTER, payload)
    case SET_AUTH_STATE:
      return state.set(AUTH_STATE, payload)
    case SET_PROFILE:
      return state.set(PROFILE, payload)
    case SET_PROFILE_REQUEST_STATUS:
      return state.set(PROFILE_REQUEST_STATUS, payload)
    case SET_PROFILE_REQUEST_ERROR:
      return state.set(PROFILE_REQUEST_ERROR, payload)
    case SET_IS_VIEW_AS_OPEN:
      return state.set(IS_VIEW_AS_OPEN, payload)
    case SET_IS_VIEW_AS_SELECTED:
      return state.set(IS_VIEW_AS_SELECTED, payload)
    case SET_VIEWING_AS:
      return state.set(VIEWING_AS, payload)

    case SET_COLLECTION_REQUEST_STATUS:
      return state.setIn(
        [COLLECTION_REQUEST_STATUS, payload.endpoint],
        payload.status
      )
    case SET_COLLECTION_REQUEST_ERROR:
      return state.setIn(
        [COLLECTION_REQUEST_ERROR, payload.endpoint],
        payload.error
      )
    case SET_COLLECTION:
      return state.setIn([COLLECTION, payload.endpoint], payload.data)

    case SET_RESOURCE_REQUEST_STATUS:
      return state.setIn(
        [RESOURCE_REQUEST_STATUS, payload.endpoint],
        payload.status
      )
    case SET_RESOURCE_REQUEST_ERROR:
      return state.setIn(
        [RESOURCE_REQUEST_ERROR, payload.endpoint],
        payload.error
      )
    case SET_RESOURCE:
      return state.setIn([RESOURCE, payload.endpoint], payload.data)

    case SET_MOBILE_MENU:
      return state.set(MOBILE_MENU, payload)
    case SET_LOCATION:
      return state.set(LOCATION, payload)

    case SET_SYNTHETIC_BREADCRUMB_ITEMS:
      return state.set(SYNTHETIC_BREADCRUMB_ITEMS, payload)

    case ADD_NOTIFICATION_POOL:
      return state.update(NOTIFICATION_POOL, list => list.push(payload))

    case REMOVE_NOTIFICATION_POOL:
      return state.update(NOTIFICATION_POOL, list =>
        list.filter(item => item && item.id !== payload)
      )

    case UPDATE_NOTIFICATION_POOL:
      return state.updateIn([NOTIFICATION_POOL, payload.id], state => ({
        ...state,
        ...payload.data
      }))

    case SET_TAB_SUBMIT_BUTTON:
      return state.set(TAB_SUBMIT_BUTTON, payload)

    default:
      return state
  }
}
