import axios from 'axios'

// modules
import { HTTP_METHODS } from '../constants'
import Url from '../url'

export const populateHeaders = (headers = {}) => {
  const appJsonHeader = 'application/json'

  return {
    ...headers,
    crossDomain: true,
    'Content-Type': headers['Content-Type'] || appJsonHeader,
    Accept: headers['Accept'] || appJsonHeader
  }
}

export const getMethodByName = (methodName = 'get') => {
  const methodNameAsLowerCase = methodName.toLowerCase()

  if (HTTP_METHODS.indexOf(methodNameAsLowerCase) > -1) {
    return (config = {}, cb = () => null) => {
      const headers = populateHeaders(config.headers)
      config.headers = headers
      const paramsSerializer = params =>
        Url.stringifySearch(params, {
          ...(config.stringifyOpts || {}),
          addQueryPrefix: false
        })
      return axios({
        method: methodNameAsLowerCase,
        paramsSerializer,
        ...config
      })
        .then(res => cb(null, res))
        .catch(err => cb(err))
    }
  }

  throw new Error(`Method ${methodName} not found in HTTP_METHODS`)
}

export default class Http {
  static get = getMethodByName('get')
  static delete = getMethodByName('delete')
  static post = getMethodByName('post')
  static put = getMethodByName('put')
  static patch = getMethodByName('patch')
}
