if (typeof window === 'undefined') {
  global.window = {}
}

export default class Env {
  static isClient(cb) {
    try {
      const _isClient = (typeof window !== 'undefined' && window.document && window.document.createElement)
      if(_isClient && cb) return cb()
      
      return _isClient
    } catch (e) {
      return false
    }
  }

  static isServer(cb) {
    const _isClient = Env.isClient()
    if(!_isClient && cb) cb()
    return !_isClient
  }

  static getWindow() {
    return Env.isClient() ? window : {}
  }

  static getDocument() {
    return Env.isClient() ? document : {}
  }
}
