export const SET_COUNTER = 'setCounter'
export const COUNTER = 'counter'

export const SET_AUTH_STATE = 'setAuthState'
export const AUTH_STATE = 'authState'

export const SET_PROFILE_REQUEST_STATUS = 'setProfileRequestStatus'
export const PROFILE_REQUEST_STATUS = 'profileRequestStatus'
export const SET_PROFILE_REQUEST_ERROR = 'setProfileRequestError'
export const PROFILE_REQUEST_ERROR = 'profileRequestError'
export const SET_PROFILE = 'setProfile'
export const PROFILE = 'authProfile'

export const SET_IS_VIEW_AS_OPEN = 'setIsViewAsOpen'
export const IS_VIEW_AS_OPEN = 'isViewAsOpen'

export const SET_IS_VIEW_AS_SELECTED = 'setIsViewAsSelected'
export const IS_VIEW_AS_SELECTED = 'isViewAsSelected'

export const SET_VIEWING_AS = 'setViewingAs'
export const VIEWING_AS = 'viewingAs'

export const SET_COLLECTION_REQUEST_STATUS = 'setCollectionRequestStatus'
export const COLLECTION_REQUEST_STATUS = 'collectionRequestStatus'
export const SET_COLLECTION_REQUEST_ERROR = 'setCollectionRequestError'
export const COLLECTION_REQUEST_ERROR = 'collectionRequestError'
export const SET_COLLECTION = 'setCollection'
export const COLLECTION = 'collection'

export const SET_RESOURCE_REQUEST_STATUS = 'setResourceRequestStatus'
export const RESOURCE_REQUEST_STATUS = 'resourceRequestStatus'
export const SET_RESOURCE_REQUEST_ERROR = 'setResourceRequestError'
export const RESOURCE_REQUEST_ERROR = 'resourceRequestError'
export const SET_RESOURCE = 'setResource'
export const RESOURCE = 'resource'

export const SET_MOBILE_MENU = 'setMobileMenu'
export const MOBILE_MENU = 'mobileMenu'

export const SET_LOCATION = 'setLocation'
export const LOCATION = 'location'

export const SET_SYNTHETIC_BREADCRUMB_ITEMS = 'setSyntheticBreadcrumItems'
export const SYNTHETIC_BREADCRUMB_ITEMS = 'syntheticBreadcrumItems'

export const NOTIFICATION_POOL = 'notificationPool'
export const ADD_NOTIFICATION_POOL = 'addNotificationPool'
export const REMOVE_NOTIFICATION_POOL = 'removeNotificationPool'
export const UPDATE_NOTIFICATION_POOL = 'updateNotificationPool'

export const SET_TAB_SUBMIT_BUTTON = 'setTabSubmitButton'
export const TAB_SUBMIT_BUTTON = 'tabSubmitButton'
