import { createStore, applyMiddleware, compose } from 'redux'
import appReducer from './reducer'
import thunk from 'redux-thunk'

// modules
import Env from '../env'

const _window = Env.getWindow()
const callbackFunction =
  Env.isClient() && _window.__REDUX_DEVTOOLS_EXTENSION__
    ? _window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f

export default createStore(
  appReducer,
  compose(
    applyMiddleware(thunk),
    callbackFunction
  )
)
