import JSCookie from 'js-cookie'
import { AUTH_COOKIE_DOMAIN } from '../constants'

export default class Cookie {
  static setAsString(namespace, value, config = {}, cb = () => null) {
    try {
      JSCookie.set(namespace, value, config)

      cb(null)
      return true
    } catch (err) {
      cb(err)
      return false
    }
  }

  static getAsString(namespace, cb = () => null) {
    try {
      const valueAsString = JSCookie.get(namespace)
      cb(null, valueAsString)
    } catch (err) {
      cb(err)
    }
  }

  static setAsJson(namespace, value, config = {}, cb = () => null) {
    try {
      JSCookie.set(namespace, JSON.stringify(value), {
        domain: AUTH_COOKIE_DOMAIN,
        ...config
      })
      cb(null)
      return true
    } catch (err) {
      cb(err)
      return false
    }
  }

  static getAsJson(namespace, cb = () => null) {
    try {
      const json = JSCookie.get(namespace)
      const result = (json && JSON.parse(json)) || {}
      if (cb) return cb(null, result)
      return result
    } catch (err) {
      if (cb) return cb(err)
      return {}
    }
  }

  static remove(namespace, config = {}, cb = () => null) {
    try {
      JSCookie.remove(namespace, {
        domain: AUTH_COOKIE_DOMAIN,
        ...config
      })
      cb(null)
    } catch (err) {
      cb(err)
    }
  }
}
