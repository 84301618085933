export const API_VERSION = 1

export const AUTH0_DOMAIN = process.env['GATSBY_AUTH0_DOMAIN'] || '_'
export const AUTH0_CLIENT_ID = process.env['GATSBY_AUTH0_CLIENT_ID'] || '_'
export const AUTH0_CALLBACK_URL = process.env['GATSBY_AUTH0_CALLBACK'] || '_'
export const AUTH0_AUDIENCE = process.env['GATSBY_AUTH0_AUDIENCE']

export const AUTH_COOKIE_DOMAIN =
  process.env['GATSBY_COOKIE_DOMAIN'] || '.aplaceformom.com'
export const AUTH_COOKIE_NAME = process.env['GATSBY_SESSION_NAMESPACE_TOKEN']
export const IMPERSONATION_COOKIE_NAME =
  process.env['GATSBY_IMPERSONATION_NAMESPACE_TOKEN']
export const IMPERSONATION_DOMAIN = process.env['GATSBY_IMPERSONATION_DOMAIN']

export const HTTP_METHODS = ['get', 'delete', 'post', 'put', 'patch']

export const LAMBDA_HEALTH_CHECK = '/rbac/health-check'

export const ADMIN_ROLE = 1 // CC administrator
export const USER_ROLE = 2
export const FINANCE_ROLE = 3
export const UTILITIES_ROLE = 4
export const IMPERSONATOR_ROLE = 5
export const MODERATOR_ROLE = 6
export const ADMINISTRATOR_ROLE = 7 // APFM administrator
export const EDIT_COMMUNITIES = 8

export const ROLES_KEY = 'https://apfm.com/roles'

export const USER_ROLES = 'roles'
export const IMPERSONATED_USER_ROLES = 'impersonatedUserRoles'

export const REST_API_ENDPOINTS = {
  leads: 'Leads',
  referrals: 'Referrals',
  referralsSearch: 'ReferralsSearching',
  communitySearch: 'communities/search',
  advisors: 'Advisors',
  communities: 'communities',
  contacts: 'Contacts',
  residents: 'Residents',
  referralStatuses: 'ReferralStatuses',
  leadActivities: 'LeadActivities',
  referralActivities: 'ReferralActivities',
  referralAlerts: 'ReferralAlerts',
  businessUnits: 'Users/businessunits',
  users: 'Users',
  usersBulkUpdate: 'Users/bulkupdate',
  userRoles: 'Users/userroles',
  availableRoles: 'Users/roles',
  breakdownStatusCounts: 'Referrals/count-by-status',
  communitiesDescription: 'communities/Description',
  communityEvents: 'communityEvents',
  communityImages: 'CommunityImages',
  coreImages: 'coreImages',
  communityAssets: 'communityAssets',
  assetChangeStatus: 'CommunityAssets/change-status',
  moderator: 'communityAssets/moderator',
  notes: 'Notes',
  personnel: 'CommunityEmployees',
  reports: 'reports',
  roles: 'CommunityEmployees/Roles',
  userPasswordReset: 'Users/passwordreset',
  usersImpersonation: 'Users/usersforimpersonation',
  usersAccount: 'Users/account'
}

export const IMAGE_TYPES = {
  photo: 1,
  logo: 2
}

export const IMAGE_STATUSES = {
  pending: 1,
  approved: 2,
  rejected: 4
}

export const IMAGE_STATUSES_MAP = new Map()
IMAGE_STATUSES_MAP.set(1, 'Pending')
IMAGE_STATUSES_MAP.set(2, 'Approved')
IMAGE_STATUSES_MAP.set(4, 'Rejected')

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const SIZES = ['xs', 'sm', 'md', 'lg']
