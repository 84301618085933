import Auth from '../auth'
import Http from '../http'

const DEFAULT_VERSION = 1

class RestApi {
  constructor(endpoint = '', version) {
    const versionedPcApiUrl =
      process.env[`GATSBY_PC_API_URL_V${version || DEFAULT_VERSION}`] ||
      process.env['GATSBY_PC_API_URL']
    this.endpoint = `${versionedPcApiUrl}/${endpoint}`
  }

  sign(headers = {}, cb = () => null) {
    Auth.getToken((err, token) => {
      if (err) return cb(err)

      const skipImpersonation = headers.skipImpersonation
      delete headers.skipImpersonation

      const headersWithToken = {
        ...headers,
        Authorization: `Bearer ${token}`
      }
      Auth.isoIsImpersonating((err, impersonationToken) => {
        if (err) return cb(err)
        if (!skipImpersonation && impersonationToken) headersWithToken.imp_sub = impersonationToken
        cb(null, headersWithToken)
      })
    })
  }
}

export class Collection extends RestApi {
  constructor(endpoint = '', version) {
    super(endpoint, version || DEFAULT_VERSION)
  }

  createSigned(data = {}, config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint
      config.data = data

      Http.post(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  readSigned(config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint

      Http.get(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  patchSigned(config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint

      Http.patch(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  putSigned(config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint

      Http.put(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  readSignedSpreadsheet(config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = {
        ...headersWithToken,
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }

      config.url = this.endpoint
      config.responseType = 'blob'

      Http.get(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  createSignedFile(data = {}, config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = {
        ...headersWithToken,
        'Content-Type': 'application/form-data'
      }

      config.url = this.endpoint
      config.data = data

      Http.post(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }
}

export class Resource extends RestApi {
  constructor(endpoint = '', version) {
    super(endpoint, version || DEFAULT_VERSION)
  }

  readSigned(id = '', config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint + `/${id}`

      Http.get(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  putSigned(id = '', config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint + `/${id}`

      Http.put(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  patchSigned(id = '', config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint + `/${id}`

      Http.patch(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }

  deleteSigned(id = '', config = {}, cb = () => null) {
    this.sign(config.headers, (err, headersWithToken) => {
      config.headers = headersWithToken
      config.url = this.endpoint + `/${id}`

      Http.delete(config, (err, res) => {
        if (err) return cb(err)
        cb(null, res)
      })
    })
  }
}
