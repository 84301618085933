import { navigate } from 'gatsby'

// modules
import Cookie from '@modules/cookie'
import Auth from '@modules/auth'
import Url from '@modules/url'

export default class Router {
  static go(url) {
    const isExternalUrl = /^http/g.test(url)
    isExternalUrl ?  window.open(url, "_blank") : navigate(url)
  }

  static goToLoginWithSecuredForwardUrl(forwardUrl, ref = null){
    Cookie.setAsString(Auth.nonce, forwardUrl || Auth.forwardUrl, {}, () => {
      const searchParams = ref ? Url.stringifySearch({ ref }) : ''
      Router.go(`/login/${searchParams}`)
    })
  }
}
