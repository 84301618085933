export default class AppError {
  static throw(message = 'unset_error_message', cb) {
    if (cb) {
      try {
        throw new Error(message)
      } catch (e) {
        cb(e)
      }
    } else {
      throw new Error(message)
    }
  }
}
