// modules
import Auth from '../auth'
import { Collection } from '@modules/rest-api'
import {
  REST_API_ENDPOINTS,
  AUTH_COOKIE_NAME,
  ROLES_KEY,
  USER_ROLES
} from '@modules/constants'

class UserRoles {
  static fetchUserRoles(cb) {
    const collection = new Collection(REST_API_ENDPOINTS.userRoles)
    collection.readSigned({}, (err, res = {}) => {
      if (err) cb(err)
      const data = res.data || {}
      const roles = data.results || []
      cb(null, roles)
    })
  }
  static getUserRoles(cb) {
    Auth.getCookie(AUTH_COOKIE_NAME, (err, cookie) => {
      if (err) return cb(err)
      const roles = cookie[ROLES_KEY] || []
      cb(null, roles)
    })
  }

  static checkRolesAccess(roles, needed, userType = USER_ROLES) {
    const userRoles = roles[userType].map(role => role.id)
    const checkRoles = needed.map(role => userRoles.includes(role))

    return !checkRoles.some(val => val === false)
  }

  static hasNeededRoles(userType, neededRoles, cb = () => null, refetch = false) {
    this.getUserRoles((err, roles = []) => {
      if (err) return cb(err)
      const hasAccess = UserRoles.checkRolesAccess(roles, neededRoles, userType)
      if (!hasAccess && refetch) {
        return Auth.refreshRolesInCookie(null, (err, dataCookie) => {
          if (err) return cb(null, hasAccess)
          const rolesFetched = dataCookie[ROLES_KEY] || []
          const hasAccessFetch = UserRoles.checkRolesAccess(rolesFetched, neededRoles, userType)
          if (hasAccessFetch !== hasAccess) {
            return window.location.replace(window.location.href + '?rc=1')
          }
          return cb(null, hasAccessFetch)
        })
      }
      cb(null, hasAccess)
    })
  }
}

export default UserRoles
